import { IconButton } from "@fluentui/react";
import { IExtendedColumn, ScheduleJEResponseFromAPI } from "@jem/components";
import React from "react";

interface IScheduleDetailsColumn extends IExtendedColumn {
  fieldName: keyof ScheduleJEResponseFromAPI;
}

interface IScheduleDetailsColumnsProps {
  onEditClick: (item: ScheduleJEResponseFromAPI) => void;
  onTerminateClick: (item: ScheduleJEResponseFromAPI) => void;
}

export const getScheduleDetailsColumns =
  ({ onEditClick, onTerminateClick }: IScheduleDetailsColumnsProps) =>
  (): IScheduleDetailsColumn[] => {
    const cols: IScheduleDetailsColumn[] = [
      {
        key: "recurringScheduleName",
        name: "Recurring schedule name",
        fieldName: "recurringScheduleName",
        type: "string",
        minWidth: 130
      },
      {
        key: "Actions",
        name: "Actions",
        fieldName: "recurringScheduleName",
        type: "string",
        minWidth: 80,
        onRender: (item: ScheduleJEResponseFromAPI): JSX.Element | React.ReactText | null => {
          return (
            <>
              <IconButton
                iconProps={{ iconName: "Edit" }}
                title="Edit"
                ariaLabel="Edit Schedule"
                styles={{
                  root: {
                    padding: 0
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onEditClick(item);
                }}
              />
              <IconButton
                iconProps={{ iconName: "Delete" }}
                title="Terminate"
                ariaLabel="Terminate Schedule"
                styles={{
                  root: {
                    padding: 0
                  }
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onTerminateClick(item);
                }}
              />
            </>
          );
        }
      },
      {
        key: "frequency",
        name: "Frequency",
        fieldName: "recurringFrequency",
        type: "string",
        minWidth: 100
      },
      {
        key: "noOfJEPosted",
        name: "No. of JE's Posted",
        fieldName: "noOfJEPosted",
        type: "number",
        minWidth: 80
      },
      {
        key: "nextPostingDate",
        name: "Next Posting Date",
        fieldName: "nextPostingDate",
        type: "string",
        minWidth: 130
      },
      {
        key: "poster",
        name: "Assigned Poster",
        fieldName: "poster",
        type: "string",
        minWidth: 130
      },
      {
        key: "reviewer",
        name: "Assigned Reviewer",
        fieldName: "reviewer",
        type: "string",
        minWidth: 130
      },
      {
        key: "recurringScheduleStatus",
        name: "Status",
        fieldName: "recurringScheduleStatus",
        type: "string",
        minWidth: 100
      },
      {
        key: "recurringScheduleGUID",
        name: "Recurring Schedule GUID",
        fieldName: "recurringScheduleGUID",
        type: "string",
        minWidth: 130
      }
    ];
    return cols;
  };
