import { css } from "@emotion/css";
import { MessageBar, MessageBarButton, MessageBarType, PivotItem, Separator, useTheme } from "@fluentui/react";
import { Form, Formik, FormikProps } from "formik";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { PageStyles } from "../../../Shared/utilities/PageUtilities";
import { useGLAttachmentActionsRequests } from "../../shared/GL.AttachmentActionsRequests";
import { GLAttachmentsRef } from "../../shared/GL.Attachments";
import { GLCreateActions } from "./GLCreate.Actions";

import { JemConfiguration } from "../../../JemConfiguration";
import {
  ApiError,
  GLCommentsModel,
  LoadingStatus,
  LocalMessage,
  MockDataFn,
  UserContext,
  useQuery
} from "../../../Shared";
import { PageHeading } from "../../../Shared/components/PageHeading/PageHeading";
import { DomainDataEnum, DomainDataObjects } from "../../../Shared/contexts/JEMContext/JEMContext.domainData.types";
import { LoggingContext } from "../../../Shared/contexts/LoggingContext/LoggingContext";
import { LineAmountFormat } from "../../utilities/GetLineAmountFormat";
import { glCreateOfflineValidation } from "../../validation/CheckForErrorsInTabs";
import { GLActionResult, UIActionDelegation } from "./Actions/GLCreate.UIActionDelegation";
import { useGLCreateActions } from "./Actions/GLCreateActions";
import { JETitle } from "./GLCreate.JETitle";
import { LineAmountCalculation, LineAmountOptions } from "./GLCreate.LineAmountCalculation";
import { fetchDraftFromEndpoint, fetchJeDraftFromEndpoint, fetchNewDraft } from "./GLCreate.requests";
import { createBlankCreateState, CreatePageType, createStateToFormikState, GLPageState } from "./GLCreate.State";
import { GLCreateProps, GLCreateTabNames } from "./GLCreate.types";
import { GLCreateFormikState, GLCreateOverrides, LineItemModel } from "./GLCreateForm.types";
import { GLCreateLineItems } from "./GLCreateLineItems/GLCreateLineItems";
import { GLCreateTableRef } from "./GLCreateLineItems/GLCreateLineItems.types";
import GLCreatePivotItems from "./GLCreatePivotItems";
import { AdditionalData } from "../../shared/GL.ActionsRequests";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { GLCreateActionsEnum, useWeightages } from "./useWeightage";
import { useSearchParams } from "react-router-dom";

const isEqual = require("lodash/isEqual");

export interface GLCreateFormSettings {
  refGuid: string | null;
  templateId: string;
  defaultOverrides?: Partial<GLCreateOverrides>;
  uniqueKey?: string;
}

export interface GLCreateFormProps extends GLCreateProps {
  configuration: Pick<JemConfiguration, "GeneralLedgerApi" | "DocumentsApi" | "featureFlags" | "environment">;
  domainData: Pick<
    DomainDataObjects,
    | DomainDataEnum.JeCompanyCodes
    | DomainDataEnum.JeTypes
    | DomainDataEnum.JeReasonCodes
    | DomainDataEnum.CurrencyCodes
    | DomainDataEnum.FiscalPeriods
    | DomainDataEnum.StatusActions
    | DomainDataEnum.JeStatus
    | DomainDataEnum.JeParameters
  > | null;
  settings: GLCreateFormSettings | null;
  mockJeDataFn?: MockDataFn<any>;
  onNavigate: (refguid: string, navigateBack: boolean, recurringJeDetails?: any) => void;
}

let GLCreateForm: React.FC<GLCreateFormProps> = (props) => {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const isRecurringTab = searchParams.get("isReccuringTab");

  const domainData = props.domainData || {
    JeTypes: [],
    JeCompanyCodes: [],
    JeReasonCodes: [],
    FiscalPeriods: [],
    CurrencyCodes: [],
    StatusActions: [],
    JeStatus: [],
    JeParameters: []
  };
  const initialState = useMemo(() => createBlankCreateState(), []);

  const [message, setMessage] = useState<LocalMessage | null>(null);
  const [previousActionResult, setPreviousActionResult] = useState<GLActionResult | null>(null);

  const logger = useContext(LoggingContext);
  const attachmentActions = useGLAttachmentActionsRequests(
    props.configuration.DocumentsApi,
    `${props.configuration.GeneralLedgerApi.baseApiUrl}${props.configuration.GeneralLedgerApi.endpoints.attachmentDelete}`
  );
  const [currentTab, setCurrentTab] = useState<GLCreateTabNames>(
    previousActionResult && previousActionResult.message.type === MessageBarType.error
      ? GLCreateTabNames.errorDetails
      : isRecurringTab
      ? GLCreateTabNames.recurringJeSchedule
      : GLCreateTabNames.jeDetails
  );
  const formikRef = useRef<FormikProps<GLCreateFormikState>>(null);
  const [actionSettings, setActionSettings] = useState<null | {
    userAction: GLCreateActionsEnum;
    options?: AdditionalData;
  }>(null);
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);
  const [disabled, setDisabled] = useState<boolean>(props.settings === null);
  const [cacheKey, setCacheKey] = useState<[string] | [string, GLCreateFormSettings]>(["je"]);

  const lineItemsRef = useRef<GLCreateTableRef>(null);
  const attachmentsRef = useRef<GLAttachmentsRef>(null);
  const [counter, setCounter] = useState(0);
  const [fetchJeDraft, setFetchJeDraft] = useState(false);
  const [templateId, setTemplateId] = useState<string | null>(null);
  useEffect(() => {
    setDisabled(props.settings === null);
    if (props.settings) {
      const uniqueKey = props.settings.refGuid ? props.settings.refGuid : new Date().getTime().toString();
      setCacheKey(["je", { refGuid: props.settings.refGuid, templateId: props.settings.templateId, uniqueKey }]);
    }
  }, [props.settings]);

  useEffect(() => {
    setFetchJeDraft(searchParams.get("fetchJeDraft") === "true");
    setTemplateId(searchParams.get("TemplateId"));
  }, [searchParams]);

  const { isLoading, data, error, isFetching, refetch } = useQuery({
    queryKey: cacheKey,
    queryFn: (queryOptions) => {
      const [, settings] = queryOptions.queryKey as [string, GLCreateFormSettings];
      if (settings.refGuid === null) {
        logger.appInsights?.trackEvent({
          name: "createNewDraft"
        });
        return fetchNewDraft(props.configuration, props.domainData, userContext, {
          Templateid: settings.templateId
        });
      }
      // eslint-disable-next-line no-constant-condition
      if (fetchJeDraft) {
        logger.appInsights?.trackEvent({
          name: "fetchJeDraftFromEndpoint",
          properties: {
            refGuid: settings.refGuid,
            containsOverrides: !!settings.defaultOverrides
          }
        });
        return fetchJeDraftFromEndpoint(
          props.configuration,
          props.domainData,
          userContext,
          {
            RefGuid: settings.refGuid,
            Templateid: settings.templateId
          },
          props.settings?.defaultOverrides
        );
      } else {
        logger.appInsights?.trackEvent({
          name: "fetchDraftFromEndpoint",
          properties: {
            refGuid: settings.refGuid,
            containsOverrides: !!settings.defaultOverrides
          }
        });
        console.log("fetchDraftFromEndpoint", props.settings?.defaultOverrides);
        return fetchDraftFromEndpoint(
          props.configuration,
          props.domainData,
          userContext,
          {
            RefGuid: settings.refGuid,
            Templateid: settings.templateId
          },
          props.settings?.defaultOverrides
        );
      }
    },
    enabled: cacheKey.length > 1,
    staleTime: 0
  });

  const extractSettings = (cacheKey) => {
    if (cacheKey.length > 1) {
      const [, settings] = cacheKey as [string, GLCreateFormSettings];
      return settings;
    }
    return null;
  };

  const actions = useGLCreateActions(
    props.configuration,
    userContext,
    domainData,
    {
      RefGuid: extractSettings(cacheKey)?.refGuid ?? "",
      Templateid: extractSettings(cacheKey)?.templateId ?? ""
    },
    props.settings?.defaultOverrides
  );

  const theState = data !== null && data !== undefined ? (data as GLPageState) : initialState;
  const [theLineItemsState, setLineItemsTheState] = useState<LineItemModel[]>(theState.lineItems);
  const [postersTab, setPostersTab] = useState<GLCommentsModel[]>(theState.createState.posters.table);
  const buttonsState = useWeightages(theState.createState.rowWeightage, domainData);

  const [formikState, setFormikState] = useState<GLCreateFormikState>(
    createStateToFormikState(domainData, theState, previousActionResult)
  );

  useEffect(
    function handleLoading() {
      if (!props.settings || isLoading || isFetching) {
        // we are still loading here
        if (loadingStatus !== LoadingStatus.Pending) setLoadingStatus(LoadingStatus.Pending);
        return;
      }

      if (error) {
        console.log("handleLoading error", error);
        setMessage({
          type: MessageBarType.error,
          message: (error as any).message || "An error occurred while loading the data."
        });
        setDisabled(true);
        setLoadingStatus(LoadingStatus.Resolved);
        return;
      }

      if (!isLoading && !isFetching && data && data.pageConfiguration.refGuid && props.settings.refGuid === null) {
        console.log("handleLoading new draft", data.pageConfiguration.refGuid);
        setPreviousActionResult(null);
        setMessage(null);
        setFormikState(createStateToFormikState(domainData, createBlankCreateState(), null));
        if (fetchJeDraft) {
          props.onNavigate(data.pageConfiguration.refGuid, false, { fetchJeDraft, templateId });
        } else {
          props.onNavigate(data.pageConfiguration.refGuid, false);
        }
        return;
      }

      if (!data) return;
      if (props.settings.refGuid !== data.pageConfiguration.refGuid) return;

      console.log("handleLoading existing draft loaded", data.pageConfiguration.refGuid, previousActionResult);
      const newFormikState = createStateToFormikState(domainData, data, previousActionResult);
      setFormikState(newFormikState);

      const timer = setTimeout(() => {
        setLoadingStatus(LoadingStatus.Resolved);
      }, 150);

      return () => {
        if (timer) clearTimeout(timer);
      };
    },
    [isFetching, isLoading, data, error]
  );

  useEffect(
    function handleButtonsRefresh() {
      if (!props.settings || isLoading || isFetching) return;
      if (!data) return;
      if (props.settings.refGuid !== data.pageConfiguration.refGuid) return;

      console.log("handleButtonsRefresh", data.pageConfiguration.refGuid);
      setDisabled(!buttonsState.Save || theState.pageConfiguration.isReadOnly);
    },
    [buttonsState]
  );

  useEffect(
    function processAction() {
      // The effect has to depend on setFormikState. If you're here for a change of value rather than a real action, just leave.
      if (actionSettings === null) return;

      // Each action must reset the action to NONE to avoid repeating the action if the values change again.
      setActionSettings(null);
      const userAction = actionSettings.userAction;
      if (userAction === GLCreateActionsEnum.AttachFile) {
        setCurrentTab(GLCreateTabNames.attachments);
      } else {
        if (!formikRef.current) {
          // This should never happen.
          logger.appInsights?.trackException({ exception: new Error("formikRef.current is null") });
          return;
        }
        if (!lineItemsRef.current) {
          // This should never happen.
          logger.appInsights?.trackException({ exception: new Error("lineItemsRef.current is null") });
          return;
        }
        if (!attachmentsRef.current) {
          // This should never happen
          logger.appInsights?.trackException({ exception: new Error("attachmentsRef.current is null") });
          return;
        }

        setLoadingStatus(LoadingStatus.Pending);
        setMessage(null);

        UIActionDelegation({
          userAction: actionSettings.userAction,
          actionOptions: actionSettings.options,
          userChanges: formikRef.current.values,
          lineItemsRef: lineItemsRef.current,
          attachmentsRef: attachmentsRef.current,
          initialState: theState,
          configuration: props.configuration,
          userContext,
          domainData,
          actions
        })
          .then((result) => {
            let hasError = false;
            if (formikRef.current) {
              if (result.lineItemsErrors && result.lineItemsErrors.length > 0) {
                formikRef.current.setFieldValue("errorTabErrorTable", result.lineItemsErrors);
                hasError = true;
              } else {
                formikRef.current.setFieldValue("errorTabErrorTable", []);
              }
              if (result.genericHeaderErrors && result.genericHeaderErrors.length > 0) {
                formikRef.current.setFieldValue("genericHeaderErrors", result.genericHeaderErrors);
                hasError = true;
              } else {
                formikRef.current.setFieldValue("genericHeaderErrors", []);
              }
            }

            setPreviousActionResult(result);
            setMessage(result.message);

            if (
              (result.action === GLCreateActionsEnum.Validate || result.action === GLCreateActionsEnum.Save) &&
              result.reponsePageState
            ) {
              const newFormikState = createStateToFormikState(
                domainData,
                result.reponsePageState,
                previousActionResult
              );
              setFormikState(newFormikState);
              setLineItemsTheState(result.reponsePageState.lineItems);
              setPostersTab(result.reponsePageState.createState.posters.table);
            }

            if (hasError) {
              setCurrentTab(GLCreateTabNames.errorDetails);
            }

            if (props.settings) {
              if (result.blockPage) {
                setDisabled(true);
              } else if (result.action !== GLCreateActionsEnum.Validate && result.action !== GLCreateActionsEnum.Save) {
                refetch();
              }
            } else {
              props.onNavigate(theState.pageConfiguration.refGuid, false);
            }
            if (result.action === GLCreateActionsEnum.PreReview && result.message.type === MessageBarType.success) {
              setCounter(counter + 1);
            }
          })
          .catch((e) => {
            logger.appInsights?.trackException({
              exception: e,
              severityLevel: SeverityLevel.Error,
              properties: {
                userAction: userAction,
                refGuid: props.settings?.refGuid
              }
            });
            let message = `An error occurred while processing your request. Please try again later.`;
            if (e instanceof ApiError) {
              message = e.message;
            }
            const errorActionResult: GLActionResult = {
              action: userAction,
              message: {
                message: message,
                type: MessageBarType.error
              },
              blockPage: false
            };
            setPreviousActionResult(errorActionResult);
            setMessage(errorActionResult.message);
            if (userAction !== GLCreateActionsEnum.Save && userAction !== GLCreateActionsEnum.Clear) {
              refetch();
            }
          })
          .finally(() => {
            setLoadingStatus(LoadingStatus.Resolved);
          });
      }
    },
    [actionSettings, setFormikState]
  );

  const handleTabClick = (item: PivotItem) => {
    const tileName = item.props.itemKey as GLCreateTabNames;
    setCurrentTab(tileName);
  };

  useEffect(() => {
    setLineItemsTheState(theState.lineItems);
    setPostersTab(theState.createState.posters.table);
  }, [theState]);

  useEffect(() => {
    sessionStorage.setItem("prereviewcounter", counter.toString());
  }, [counter]);
  return (
    <>
      <div className={PageStyles.glPage}>
        <Formik
          enableReinitialize={true}
          initialValues={formikState}
          onSubmit={setFormikState}
          validateOnChange={false}
          validateOnBlur={true}
          validateOnMount={props.settings && props.settings.refGuid ? true : false}
          innerRef={formikRef}
          validationSchema={glCreateOfflineValidation(
            previousActionResult ? previousActionResult.action : GLCreateActionsEnum.Save,
            domainData,
            theState.createState,
            userContext.user.alias
          )}
        >
          {(formik) => (
            <Form>
              <PageHeading
                overrideRootStyles={css`
                  margin: 0;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <GLCreateActions
                  onAction={async function (actionName: GLCreateActionsEnum, options?: AdditionalData) {
                    try {
                      await formik.submitForm();
                      setActionSettings({ userAction: actionName, options: options });
                    } catch (e) {
                      console.log(e);
                    }
                  }}
                  buttonsState={buttonsState}
                  disabled={disabled || loadingStatus !== LoadingStatus.Resolved}
                  userContext={userContext}
                  createState={theState.createState}
                  domainData={domainData}
                />
              </PageHeading>
              <div
                ref={null}
                className={css`
                  width: 100%;
                  min-height: 1px;
                  box-sizing: border-box;
                  overflow: hidden;
                `}
              >
                <Separator
                  styles={{
                    root: {
                      margin: "0px",
                      padding: "0px",
                      height: "1px",
                      backgroundColor: theme.palette.neutralLighterAlt,
                      ":before": {
                        backgroundColor: theme.palette.neutralTertiaryAlt
                      }
                    }
                  }}
                />
              </div>
              <div
                className={css`
                  padding: 0 16px 0 16px;
                  padding-top: 0px;
                  background-color: ${theme.palette.neutralLighterAlt};
                `}
              >
                <JETitle
                  theme={theme}
                  state={theState}
                  disabled={disabled}
                  loadingStatus={loadingStatus}
                  onNavigate={function (): void {
                    props.onNavigate("", true);
                  }}
                />
              </div>
              {theState.createState.isExternalDraft ? (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <MessageBar messageBarType={MessageBarType.info} isMultiline={false}>
                      This Journal Entry is linked to Recon Intelligence. Some fields and line items may not be
                      editable.
                    </MessageBar>
                  </div>
                </div>
              ) : null}
              {!message ? null : (
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <MessageBar
                      messageBarType={message.type}
                      isMultiline={false}
                      dismissButtonAriaLabel="Close"
                      onDismiss={() => setMessage(null)}
                      actions={
                        disabled ? (
                          <div>
                            <MessageBarButton onClick={() => props.onNavigate("", true)}>
                              Back to Dashboard
                            </MessageBarButton>
                          </div>
                        ) : undefined
                      }
                    >
                      {message.message}
                    </MessageBar>
                  </div>
                </div>
              )}
              <GLCreatePivotItems
                configuration={props.configuration}
                disabled={disabled}
                loadingStatus={loadingStatus}
                currentTab={currentTab}
                onTabChange={handleTabClick}
                initialState={theState}
                postersInitialState={postersTab}
                domainData={domainData}
                attachmentsRef={attachmentsRef}
                onUploadFile={attachmentActions.uploadAttachment}
                onDeleteFile={attachmentActions.deleteAttachment}
                onDownloadFile={attachmentActions.downloadAttachment}
                hasMsSalesPermission={userContext.jemUser.hasMsSalesAccess}
              />

              <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                  <div className="ms-Grid-col ms-sm12">
                    <Separator></Separator>
                    <GLCreateLineItems
                      disabled={disabled || loadingStatus !== LoadingStatus.Resolved}
                      customRef={lineItemsRef}
                      lineItems={theLineItemsState}
                      pageConfiguration={theState.pageConfiguration}
                      status={loadingStatus}
                      precision={LineAmountFormat.GetLineAmountFormat(domainData, formik.values.detailsTabCurrency)}
                      onCellDataChanged={(columnName, items, settings) => {
                        console.log("onCellDataChanged");
                        if (columnName === "lineAmount") {
                          LineAmountCalculation(items, LineAmountOptions.LineAmount, formik, settings);
                        } else if (columnName === "lineAmountInCurrency") {
                          LineAmountCalculation(items, LineAmountOptions.LineAmountInCurrency, formik, settings);
                        } else if (columnName === "lineAmountLC2") {
                          LineAmountCalculation(items, LineAmountOptions.FourCurrency, formik, settings);
                        } else if (columnName === "lineAmountLC3") {
                          LineAmountCalculation(items, LineAmountOptions.FourCurrency, formik, settings);
                        }

                        formik.setFieldValue("errorTabErrorTable", items[1]);
                      }}
                      onLineItemPasted={(items, settings) => {
                        console.log("onLineItemPasted in GLCreateForm");
                        let lineAmountOption: LineAmountOptions;
                        if (settings.pageConfiguration.pageType === CreatePageType.FourCurrency) {
                          lineAmountOption = LineAmountOptions.FourCurrency;
                        } else {
                          lineAmountOption = LineAmountOptions.Both;
                        }
                        LineAmountCalculation(items, lineAmountOption, formik, settings);
                        formik.setFieldValue("errorTabErrorTable", items[1]);
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

GLCreateForm = React.memo(GLCreateForm, (prevProps, nextProps) => {
  if (!isEqual(prevProps.settings, nextProps.settings)) return false;
  if (!isEqual(prevProps.configuration, nextProps.configuration)) return false;
  if (!isEqual(prevProps.domainData, nextProps.domainData)) return false;
  return true;
});

GLCreateForm.displayName = "GLCreateForm";
export { GLCreateForm };
