import { ApiConfiguration } from "../Shared/utilities/ApiRules";

export interface GeneralLedgerApi extends ApiConfiguration {
  baseApiUrl: string;
  cacheTimeInHours: number;
  endpoints: {
    getRoles: "api/v1.0/User/Profile/roles";
    getClaims: "api/v1.0/User/ProfileInfo";
    getTileCountPrePosting: "api/v1.0/Je/PrePosting/InitDashboard";
    getTileCountPostPosting: "api/v1.0/Je/PostPosting/InitDashboard";
    getPendingJEDrafts: "api/v1.0/Je/PendingJEDrafts";
    getPendingReviewJEDrafts: "api/v1.0/Je/PendingReviewJEDrafts";
    getPostPostingGrids: "api/v1.0/Search/je/{requestguid}";
    getUserProfile: "api/v1.0/User/Profile/new";
    getRefreshedUserProfile: "api/v1.0/User/Profile/refresh";
    getBulkFromListOfIds: "api/v1.0/je/Bulk";
    isDraft: "api/v2.0/Je/IsDraft/{refGuid}";
    createDraft: "api/v2.0/Je/CreateJEDraft/{templateId}";
    getDraft: "api/v2.0/Je/JeDraft/{refguid}";
    getJeError: "api/v1.0/Je/JeError/{refguid}";
    fetchBulkDetails: "api/v1.0/Je/Bulk";
    getMarkDetails: "api/v2.0/Je/{jeId}/mark/1";
    getDetails: "api/v2.0/Je/{jeId}/details";
    getBatch: "api/v2.0/Batch/JeBatch/{refguid}";
    getBatchErrors: "api/v2.0/Batch/Errors/{refguid}";
    getInitBatch: "api/v1.0/dashboard/InitBatch";
    s2SGetDashboard: "api/v2.0/s2s/{fiscalYear}/{fiscalPeriod}/{appId}";
    s2SGetJE: "api/v1.0/s2s/{jeid}";
    s2sRetryAction: "api/v1.0/s2s/retry";
    getGLDashboardCounts: "api/v1.0/dashboard/tile/count";
    getGLDashboard: "api/v1.0/dashboard/tile/{tileName}";
    getAllTenantDetails: "api/v1.0/Admin/Tenant/AllTenantDetails";
    onboardTenantDetails: "api/v1.0/Admin/Tenant/Save";
    updateTenantDetails: "api/v1.0/Admin/Tenant/Update";
    getUserDashboardCounts: "api/v1.0/Admin/Tile/Count/{userAlias}";
    getUserDashboard: "api/v1.0/Admin/tile/{tileName}/{userAlias}/{isAdmin}";
    attachmentUpdate: "api/v1.0/Attachment/Update";
    attachmentDelete: "api/v1.0/Attachment/Delete";
    checkUsersPersona: "api/v1.0/User/{users}/Persona";
    cSaveDraft: "api/v2.0/Je/SaveJeDraft/{draftName}";
    cPostDraft: "api/v2.0/Je/PostJeDraft/{guid}";
    cValidateDraft: "api/v2.0/Je/ValidateJeDraft/{guid}";
    cSendToPosterDraft: "api/v2.0/Je/sendPosterDraft";
    cNeedsClarificationDraft: "api/v2.0/Je/ClarifyDraft";
    dashboardDraftDelete: "api/v2.0/Je/DeleteDraft";
    dashboardDraftRecall: "api/v2.0/Je/RecallDraft";
    dashboardDraftAddPoster: "api/v2.0/Je/AddPosterForDraft";
    dashboardDraftSendPoster: "api/v2.0/Je/sendPosterDraft";
    dashboardWorkflowGuid: "api/v2.0/Je/{wfGUID}";
    dashboardAddPoster: "api/v2.0/Je/AddPoster";
    dashboardAddReviewer: "api/v2.0/Je/AddReviewer";
    validateBatchfile: "api/v2.0/batch/ValidBatchFile";
    saveBatch: "api/v2.0/batch";
    validateBatch: "api/v2.0/batch/Validate";
    postBatch: "api/v2.0/batch/SAPPost";
    getBatchSearchResults: "api/v1.0/Search/Batch/{refguid}";
    getBatchSearchDetailsResults: "api/v2.0/Search/Batch/{batchGuid}/Details";
    getJESearchResults: "api/v1.0/Search/Je/{refguid}";
    detailsSave: "api/v1.0/Je/Save";
    detailsSaveSAPInfo: "api/v2.0/Je/SaveSapInfo";
    getJENumberSearchSAPResults: "api/v1.0/Search/SAP/{Compcode}/{DocNum}/{Fyear}/{Module}";
    bpoDashboardGridData: "api/v1.0/BPO/Dashboard/Checklist/company/{companycode}/status/{status}";
    postFetchInfoFromSap: "api/v1.0/Search/JeInfoFromSap";
    getJENumberSearchResults: "api/v2.0/Search/Number/{refguid}";
    bpoDashboardDeleteChecklists: "api/v2.0//BPO/Dashboard/ChecklistsToDelete";
    createChecklist: "api/v2.0/BPO/Dashboard/Checklist";
    getChecklist: "api/v2.0/BPO/Checklist/{refguid}";
    saveChecklist: "api/v2.0/BPO/Save";
    publishChecklist: "api/v2.0/BPO/Publish/{refguid}";
    signoffChecklist: "api/v2.0/BPO/Signoff/{refguid}";
    fcwGetOpsDetails: "api/v2.0/FCW/OpsDetails";
    fcwGetTaskProperty: "api/v2.0/FCW/FCCWTaskProperty/{propertyName}";
    fcwGetChecklistsFiltered: "api/v2.0/FCW/checklist/{fiscalYear}/{fiscalPeriod}/{opsDetailId}";
    fcwGetChecklistsForJE: "api/v2.0/FCW/checklist/je/{fiscalYear}/{fiscalPeriod}";
    fcwGetJEMChecklistTasks: "api/v2.0/FCW/checklist/tasks/{ChecklistRefGuid}";
    fcwPostChecklist: "api/v2.0/FCW/Checklist/Save";
    fcwPutPublish: "api/v2.0/FCW/Checklist/{checklistRefguid}/Publish";
    fcwPutBulkDeleteChecklistTasks: "api/v2.0/FCW/Checklist/{checklistRefguid}/BulkDelete";
    fcwPutBulkEditChecklistTasks: "api/v2.0/FCW/Checklist/{checklistRefguid}/BulkEdit";
    fcwPutClone: "api/v2.0/FCW/Checklist/{sourcechecklist}/clone/{targetchecklist}";
    fcwPutNoActivity: "api/v2.0/FCW/Checklist/{jetype}/{refguid}/noactivity";
    fcwDeleteChecklistTask: "api/v2.0/FCW/Checklist/task/{RefGuid}";
    fcwGetChecklist: "api/v2.0/FCW/checklist/{RefGuid}";
    fcwPutNoActivityWithPayload: "api/v2.0/FCW/Checklist/noactivity";
    fcwSearchTasks: "api/v2.0/FCW/checklist/tasks/{fiscalYear}/{fiscalPeriod}";
    fcwAagLevel3: "api/v2.0/FCW/checklist/GridData";
    getCalendarInfo: "api/v1.0/Home/Calendar";
    getIhccCalendarInfo: "api/v1.0/Home/IhccCalendar";
    getMaxHomeContentLengths: "api/v1.0/home/NewsNotification/GetMaxHomeContentLengths";
    getNewsNotifications: "api/v1.0/home/NewsNotification";
    getDelegateReviewers: "api/v1.0/User/Delegate";
    fcwImportTasks: "api/v2.0/FCW/Checklist/{checklistRefguid}/bulk";
    fcwSignOffChecklist: "api/v2.0/FCW/Checklist/SignOff";
    fcwChecklistSummary: "api/v2.0/fcw/checklist/summary";
    preReviewDraft: "api/v2.0/Je/PreReviewJeDraft/{guid}";
    preReviewBatch: "api/v2.0/Batch/PreReviewSubmit/{batchGuid}";
    postPreReview: "api/v2.0/Je/PostPreReview";
    approvedPreReviewDrafts: "api/v2.0/Je/PreReview/ApprovedDrafts";
    approvePreReview: "api/v2.0/Je/PreReview/Approve";
    approvePreReviewBatch: "api/v2.0/Batch/PreReviewApprove/{batchGuid}";
    recallPreReview: "api/v2.0/Je/PreReview/Recall";
    recallPreReviewBatch: "api/v2.0/Batch/PreReviewRecall/{batchGuid}";
    needsClarificationPreReview: "api/v2.0/Je/PreReview/Clarify";
    needsClarificationPreReviewBatch: "api/v2.0/Batch/PreReviewClarify/{batchGuid}";
    postReverseJEs: "api/v2.0/Je/Reverse";
    getRecurringSchedules: "api/v2.0/Je/GetRecurringDraftDetails";
    terminateRecurringSchedule: "api/v2.0/Je/TerminateRecurringSchedule";
    getJEDraft: "api/v2.0/Je/{templateId}/draftresponse";
  };
}

export const AttachmentRegionsToId = {
  Redmond: 2,
  Europe: 3,
  Asia: 4
};

export const IdToAttachmentRegion = {
  2: "Redmond",
  3: "Europe",
  4: "Asia"
};

export const FCCWTaskProperty = {
  MissedSlaReason: "Missed SLA Reason",
  ReasonReactivated: "Reason Reactivated"
};

export enum SignoffStatus {
  Open = 2,
  SignedOff = 3
}

export enum SignoffMode {
  Reopen,
  SignOff
}
